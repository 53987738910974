import { useTranslation } from 'react-i18next'
import QRCode from 'react-qr-code'
import { useSelector } from 'react-redux'
import ReactToPrint from 'react-to-print'

import { useGetConfiguratorQuery } from '../../../api/services/configuratorApi'
import { useGetStampFieldsForEditFileQuery } from '../../../api/services/stampsApi'
import NewModal from '../../root/modals/NewModal'

const PrintStampModal = ({ open, onClose, data, componentRef }) => {
  const { t } = useTranslation()

  const company = useSelector((state) => state.company.company)

  const { data: fileds } = useGetStampFieldsForEditFileQuery(
    { companyId: company?.id, fileId: data.fileId },
    {
      skip: !data.fileId || !company?.id,
    },
  )

  const { data: config } = useGetConfiguratorQuery(company?.id)

  const useQRcode = config?.qr

  //Translateing and reducing to a desiered object
  let selectedStamp = []

  if (fileds)
    Object.entries(fileds)?.reduce((prev, curr, index) => {
      if (curr[0] !== 'undefined') {
        let value =
          curr[0] === 'Stamp ID'
            ? 'Šifra pečata'
            : curr[0] === 'Subject'
              ? 'Predmet'
              : curr[0] === 'Client'
                ? 'Klijent'
                : curr[0] === 'Location'
                  ? 'Lokacija'
                  : curr[0] === 'Date'
                    ? 'Datum'
                    : curr[0] === 'DateOfDocument'
                      ? 'Datum dokumenta'
                      : curr[0] === 'Organisational Unit'
                        ? 'Organizaciona jedinica'
                        : curr[0] === 'Label'
                          ? 'Oznaka'
                          : curr[0] === 'User'
                            ? 'Protokolisao'
                            : curr[0]
        selectedStamp[index] = { attributeKey: value, attributeValue: curr[1] }
      }
    }, [])

  const stampToQR = selectedStamp?.map((obj) => [obj.attributeKey, obj.attributeValue])

  return (
    <>
      <NewModal
        showModal={open}
        setShowModal={onClose}
        title={'Print '}
        btns
        titleClose={t('close')}
        onClickClose={onClose}
        printStamp={true}
        componentRef={componentRef.current}
        useQRcode={useQRcode}
      >
        <div>
          <section className="usestamp-table">
            <table className="CS-62mm scale-[85%]" id="table" ref={componentRef}>
              {selectedStamp?.map((inputField, index) => {
                if (inputField.attributeKey === 'fileId') {
                  return
                }
                return (
                  <tbody key={index}>
                    <tr className="CS-th_th">
                      <th className="CS-th_th">{inputField.attributeKey}</th>
                      <td className="CS-td">{inputField.attributeValue}</td>
                    </tr>
                  </tbody>
                )
              })}
              {useQRcode && (
                <tbody>
                  <tr className="CS-th_th">
                    <th className="CS-th_th"></th>
                    <td className="CS-td">
                      <div>
                        <QRCode value={JSON.stringify(stampToQR)} />
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </section>
        </div>
      </NewModal>
    </>
  )
}

export default PrintStampModal
