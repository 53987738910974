import { Form, useFormikContext } from 'formik'
import { t } from 'i18next'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { useDuplicateSubjectCheckMutation } from '../../api/services/filesApi'
import { values as options } from '../../data/Values/values'
import usePartners from '../../hooks/createstamp/usePartners'
import { debounce } from '../../utils'
import DateInput from '../inputs/DateInput'
import SelectInput from '../inputs/SelectInput'
import RIFSelect from '../inputs/selects/RIFSelect'
import VATSelect from '../inputs/selects/VATSelect'
import TextInput from '../inputs/TextInput'

const StampForm = ({ companyStampFields, setFormikValues, handleSubmit }) => {
  const { company } = useSelector((state) => state.company)

  const { values, setFieldValue } = useFormikContext()

  const [checkDuplicateSubject] = useDuplicateSubjectCheckMutation()

  const partners = usePartners()

  const partnerOptions = partners?.map((partner) => ({ label: partner.name, value: partner.name }))

  const valuesOptions = options?.map((value) => ({ label: value.label, value: value.label }))

  useEffect(() => {
    setFormikValues(values)
  }, [values, setFormikValues])

  useEffect(() => {
    if (values?.Klijent && values.Klijent.trim() !== '' && values?.Predmet && values.Predmet.trim() !== '') {
      fetchApiData(values.Predmet)
    }
  }, [values.Klijent])

  // Checking for duplicates
  const fetchApiData = debounce((text) => {
    if (values?.Klijent && values.Klijent.trim() !== '') {
      // Fetch API using the input value
      checkDuplicateSubject({
        companyId: company?.id,
        subject: text,
        partnerName: values?.Klijent,
      })
        .then((res) => {
          // Find the matching subject
          if (res?.data?.foundDuplicate === true) {
            toast.info(t('dup_sub'))
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error)
        })
    }
  }, 500)

  return (
    <Form className="flex flex-col justify-between gap-2 h-full overflow-y-auto">
      <div className="grid grid-cols-2 gap-2">
        {companyStampFields?.map((field) => {
          return field.attributeKey === 'Datum protokola' ||
            field.attributeKey === 'Datum dokumenta' ||
            field.attributeKey === 'Datum predmeta' ? (
            <DateInput
              key={field.attributeKey}
              name={field?.attributeKey}
              placeholderText={`${field?.attributeKey}`}
              preventOpenOnFocus
              noLabel
              onBlur={() => handleSubmit(values, true)}
            />
          ) : field.attributeKey === 'Klijent' && company?.tax === true ? (
            <SelectInput
              key={field.attributeKey}
              options={partnerOptions}
              name={field.attributeKey}
              placeholder={field.attributeKey}
              value={partnerOptions?.find((partner) => partner.label === values[field.attributeKey])}
              noLabel
              onBlur={() => handleSubmit(values, true)}
            />
          ) : field.attributeKey === 'Valuta' ? (
            <SelectInput
              key={field.attributeKey}
              options={valuesOptions}
              name={field.attributeKey}
              placeholder={field.attributeKey}
              noLabel
              onBlur={() => handleSubmit(values, true)}
            />
          ) : field.attributeKey === 'PDV' ? (
            <VATSelect
              key={field.attributeKey}
              name={field.attributeKey}
              placeholder={field.attributeKey}
              noLabel
              onBlur={() => handleSubmit(values, true)}
            />
          ) : field.attributeKey === 'RIF' && company?.name === 'BOFIL d.o.o.' ? (
            <RIFSelect
              key={field.attributeKey}
              name={field.attributeKey}
              placeholder={field.attributeKey}
              noLabel
              value={values.RIF?.length > 0 ? values.RIF.split(', ').map((key) => ({ label: key, value: key })) : null}
              onChange={(e) => {
                setFieldValue('RIF', e.map((key) => key.value).join(', '))
              }}
              onBlur={() => handleSubmit(values, true)}
            />
          ) : field.attributeKey === 'Vrijednost fakture' || field.attributeKey === 'Vrijednost bez PDV' ? (
            <TextInput
              key={field.attributeKey}
              name={field.attributeKey}
              placeholder={field.attributeKey}
              noLabel
              onBlur={() => handleSubmit(values, true)}
              type="number"
              onChange={(e) => {
                let input = e.target.value
                // Remove any non-digit characters from the input
                input = input.replace(/[^\d]/g, '')

                let event

                if (e.target.value === '0.0' || e.target.value === '') {
                  event = {
                    target: {
                      value: '',
                      name: 'attributeValue',
                    },
                  }
                } else {
                  // Add leading zeros as needed
                  while (input.length < 3) {
                    input = '0' + input
                  }

                  // Add a decimal point two characters from the end
                  input = input.slice(0, -2) + '.' + input.slice(-2)

                  // Remove any leading zeros before the decimal point
                  input = input.replace(/^0+(?=\d)/, '')

                  event = {
                    target: {
                      value: input,
                      name: 'attributeValue',
                    },
                  }
                }

                // Set the formatted input value to state
                setFieldValue(field.attributeKey, event.target.value)
              }}
            />
          ) : field.attributeKey === 'Tip dokumenta' ? null : field.attributeKey === 'Predmet' ? (
            <TextInput
              onBlur={() => handleSubmit(values, true)}
              key={field.attributeKey}
              name={field.attributeKey}
              placeholder={field.attributeKey}
              noLabel
              readOnly={field.attributeKey === 'Stamp ID'}
              onChange={(event) => {
                // We want to find if there is a Subject already in the DB
                // If yes, then just notify the user
                const newValue = event.target.value
                setFieldValue(field.attributeKey, newValue)
                fetchApiData(newValue) // Call function for API
              }}
            />
          ) : (
            <TextInput
              onBlur={() => handleSubmit(values, true)}
              key={field.attributeKey}
              name={field.attributeKey}
              placeholder={field.attributeKey}
              noLabel
              readOnly={field.attributeKey === 'Stamp ID'}
            />
          )
        })}
      </div>
    </Form>
  )
}

export default StampForm
