import axios from 'axios'
import { debounce } from 'lodash'
import { useEffect, useState } from 'react'
import React from 'react'
import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid'

import { useGetCategoryQuery } from '../../../api/services/categoriesApi'
import {
  useChangeFileCategoryMutation,
  useChangeFileMutation,
  useChangeFileTypeMutation,
} from '../../../api/services/filesApi'
import { useGetStampFieldsForEditFileQuery } from '../../../api/services/stampsApi'
import { useGetStatusesQuery } from '../../../api/services/statusApi'
import { PDFDocumentIcon } from '../../../assets/icons'
import { clientStyles } from '../../../data/createStampSelectStyles'
import { buttonStamp } from '../../../data/stamp/btnStamp'
import { values } from '../../../data/Values/values'
import usePartners from '../../../hooks/createstamp/usePartners'
import FileFormSlider from '../../forms/FileFormSlider'
import DateInputRegular from '../../inputs/DateInputRegular'
import DocumentTypeRadioButton from '../../inputs/DocumentTypeRadioButton'
import NumberInputRegular from '../../inputs/NumberInputRegular'
import SelectInputRegular from '../../inputs/SelectInputRegular'
import TextInputRegular from '../../inputs/TextInputRegular'
import NewModal from '../../root/modals/NewModal'

const EditFileCategoriesModal = ({
  open,
  onClose,
  file,
  setFile,
  selectedFile,
  setSelectedFile,
  urlPdf,
  stampPositionState,
  selectListPages,
  page,
  handleSetPage,
  handleRotate,
  changeStampPosition,
  fileTypes,
  setFileTypes,
}) => {
  const { t } = useTranslation()
  const [taxPartner, setTaxPartner] = useState(null)

  const partners = usePartners()

  const { sendFile } = useSelector((state) => state.file)
  const user = useSelector((state) => state.user?.user)

  const company = useSelector((state) => state.company.company)
  const { data: status } = useGetStatusesQuery()

  const statusOptions = status?.map((item) => {
    return {
      value: item.id,
      label:
        item.statusName === 'Approved'
          ? t('approved')
          : item.statusName === 'Rejected'
            ? t('rejected')
            : item.statusName === 'Protocol'
              ? t('awaiting')
              : null,
    }
  })

  const taxOptions = partners?.map((partner) => ({
    label: partner?.name,
    value: partner.partner_id,
  }))

  useEffect(() => {
    if (file['Klijent'] && open) {
      setTaxPartner({ label: file['Klijent'], value: file['clientId'] })
    }
    if (file['Valuta'] && open) {
      setValue({ label: file['Valuta'], value: file['Valuta'] })
    }
    if (file['PDV'] && open) {
      setPDV({ label: file['PDV'], value: file['PDV'] })
    }
  }, [open, partners])

  const valuesOptions = values?.map((value) => ({ label: value.label, value: value.label }))

  const optionsPDV = [
    { label: '17%', value: '17%' },
    { label: '0%', value: '0%' },
  ]

  const [changeFile] = useChangeFileMutation()
  const [changeFileTy] = useChangeFileTypeMutation()
  const [changeFileCat] = useChangeFileCategoryMutation()

  const [pageState, setPageState] = useState(0)
  const [fileType, setFileType] = useState()
  const [fileStatus, setFileStatus] = useState()
  const [fileCategory, setFileCategory] = useState()
  const [value, setValue] = useState()
  const [PDV, setPDV] = useState()
  const [fileStamp, setFileStamp] = useState([])
  const [editFields, setEditFields] = useState({})
  const [booked, setBooked] = useState({})

  // Update doc permission
  const canEditBooked = user?.functions?.find((fun) => fun.name === 'update-booked-document')

  useEffect(() => {
    if (file && file.statusId && file.Status) {
      setFileStatus({
        label: file.Status === 'Protocol' ? t('awaiting') : t(file?.Status?.toLowerCase()),
        value: file.statusId,
      })
    }

    if (file.booked) setBooked({ label: t('set_booked'), value: 1 })
    else setBooked({ label: t('set_not_booked'), value: 0 })
  }, [file])

  const handleChangePage = (e) => {
    e.preventDefault()
    setPageState((prev) => {
      if (prev === 0) return 1
      if (prev === 1) return 0
    })
  }

  const handleChangeBack = (e) => {
    e.preventDefault()
    setPageState((prev) => {
      if (prev === 0) {
        return 1
      } else {
        return prev - 1
      }
    })
  }

  const typeOptions = [
    { value: '1', label: t('accounting') },
    { value: '2', label: t('legal') },
    { value: '3', label: t('other') },
  ]
  const bookedOptions = [
    { value: '0', label: t('set_not_booked') },
    { value: '1', label: t('set_booked') },
  ]

  //Drag and drop
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'application/pdf',
    onDrop: (selectedFile) => {
      setSelectedFile(selectedFile[0])
    },
  })

  const { data: companyCategories } = useGetCategoryQuery(company?.id)
  const categoryOptions = companyCategories?.map((category, index) => ({
    label: category?.categoryName,
    value: category?.id,
  }))
  const { data: editDocStampFields } = useGetStampFieldsForEditFileQuery(
    { companyId: company?.id, fileId: file.id },
    { skip: !file.id },
  )

  useEffect(() => {
    if (editDocStampFields) {
      setEditFields(editDocStampFields)
    }
  }, [editDocStampFields])

  const saveChanges = async () => {
    if (file.booked === 1 && !canEditBooked) return toast.error(t('booked'))

    //Update category only if changed
    if (fileCategory) {
      const id = fileCategory.value
      changeFileCategory(file.id, id)
      toast(t('category_changed'))
    }
    if (fileType) {
      const id = parseInt(fileType.value)
      changeFileType(file.id, id)
      toast(t('type_changed'))
    }
    const newCategories = {
      clasified: fileTypes?.invoice,
      importOption: fileTypes.inSlide,
      exportOption: fileTypes.outSlide,
      advance: fileTypes?.advanced,
      issued: fileTypes?.issued,
      received: fileTypes?.received,
      reExport: fileTypes?.reExport,
      KO: fileTypes?.koDoc,
    }

    let allKeys = Object.entries({
      ...editFields,
      'Datum dokumenta': file['Datum predmeta'],
      'Datum protokola': file['Datum prijema'],
      Klijent: taxPartner.label,
      Valuta: value?.label,
      PDV: PDV?.label,
    }).reduce((prev, el) => {
      //Check if stamp field has been already edited and put into state
      const found = fileStamp.find((obj) => obj.attributeKey === el[0])
      //If not add previous stamp field value
      if (!found) {
        return prev.concat([{ attributeKey: el[0], attributeValue: el[1] }])
      } else {
        //If yes pass the edited value
        return prev.concat([
          {
            attributeKey: found.attributeKey,
            attributeValue: found.attributeValue,
          },
        ])
      }
    }, [])

    // Check if all stamp fields have been filled
    const stampNotComleated = allKeys.some((obj) => !obj.attributeValue)
    if (stampNotComleated) return toast.error(t('input_all_fields'))

    // Check if at least one doc. type was checked
    const docTypeChecked = Object.values(newCategories).every((key) => !key)
    if (docTypeChecked) return toast.error(t('input_all_fields'))

    const hasDatumDokumenta = allKeys.some((obj) => obj.attributeKey === 'Datum dokumenta')
    const hasDatumPredmeta = allKeys.some((obj) => obj.attributeKey === 'Datum predmeta')

    const filteredArray = allKeys.filter((obj) => {
      return !(hasDatumDokumenta && hasDatumPredmeta && obj.attributeKey === 'Datum dokumenta')
    })

    const newFile = {
      ...file,
      ...newCategories,
      booked: booked?.value,
      stampArr: filteredArray,
    }

    delete newFile['updatedAt']
    delete newFile['createdAt']
    delete newFile['statusId']
    delete newFile['typeId']
    delete newFile['categoryId']

    updateFile(file.id, newFile, selectedFile, file.Sifra, company?.id)
    onClose()
    setFileCategory()
    setFileStatus()
    setFileType()
    setFileStamp([])
    setFile({})
    setPageState(0)
  }

  const handleCheckbox = (key) => {
    setFileTypes((prev) => ({
      ...prev,
      outSlide: false,
      received: false,
      inSlide: false,
      koDoc: false,
      advanced: false,
      reExport: false,
      issued: false,
      [key]: !prev[key],
    }))
  }

  const handleChangeDate = useCallback(
    debounce((e, inputType, key, realKey) => {
      if (inputType === 'raw') {
        const inputValue = e.target.value
        let formattedValue = inputValue ? inputValue.replace(/[^\d]/g, '') : ''

        if (formattedValue.length > 2) {
          formattedValue = `${formattedValue.slice(0, 2)}.${formattedValue.slice(2)}` // Insert dot after two digits
        }
        if (formattedValue.length > 5) {
          formattedValue = `${formattedValue.slice(0, 5)}.${formattedValue.slice(5)}` // Insert dot after five digits
        }
        if (formattedValue.length > 10) {
          formattedValue = formattedValue.slice(0, 10)
        }

        // Update the input value immediately
        e.target.value = formattedValue

        const dateParts = formattedValue.split('.').filter(Boolean)
        const day = parseInt(dateParts[0], 10)
        const month = parseInt(dateParts[1], 10) - 1
        const year = parseInt(dateParts[2], 10) || 0
        const date = new Date(year, month, day)

        // Format the date into a string
        const value = date && date.toLocaleDateString('en-UK').replace(/\//g, '-')

        let newFile = { ...file }

        // Updating file stampkeys
        const newFileStamp = [...fileStamp]
        let found = newFileStamp.find((el) => el.attributeKey === key)

        if (found) {
          let index = newFileStamp.findIndex((el) => el.attributeKey === key)
          newFileStamp[index] = {
            attributeKey: key,
            attributeValue: value,
          }
        } else {
          newFileStamp.push({
            attributeKey: key,
            attributeValue: value,
          })
        }

        newFile[key] = value
        setFileStamp(newFileStamp)
        setEditFields((prev) => ({
          ...prev,
          [realKey]: value,
        }))
        setFile(newFile)
      }

      if (inputType !== 'raw') {
        const parsedDate = e && e.toLocaleDateString('en-UK').replace(/\//g, '-')
        let newFile = { ...file }

        // Updating file stampkeys
        const newFileStamp = [...fileStamp]
        let found = newFileStamp.find((el) => el.attributeKey === key)

        if (found) {
          let index = newFileStamp.findIndex((el) => el.attributeKey === key)
          newFileStamp[index] = {
            attributeKey: key,
            attributeValue: parsedDate,
          }
        } else {
          newFileStamp.push({
            attributeKey: key,
            attributeValue: parsedDate,
          })
        }

        newFile[key] = parsedDate

        setFileStamp(newFileStamp)
        setEditFields((prev) => ({
          ...prev,
          [realKey]: value,
        }))
        setFile(newFile)
      }
    }, 500),
    [file, fileStamp, setFile, setFileStamp, setEditFields],
  )

  //Change values on page 2
  const handeFileChange = (e, key) => {
    let newFile = { ...file }

    //Updating file stampkeys
    const newFileStamp = [...fileStamp]
    let found = newFileStamp.find((el) => el.attributeKey === key)

    if (found) {
      let index = newFileStamp.findIndex((el) => el.attributeKey === key)
      newFileStamp[index] = {
        attributeKey: key,
        attributeValue: e.target.value,
      }
    } else {
      newFileStamp.push({ attributeKey: key, attributeValue: e.target.value })
    }
    newFile[key] = e.target.value

    setFileStamp(newFileStamp)
    setFile(newFile)
  }

  //Stamp position options
  const stampPositionOptons = buttonStamp.map((btn) => {
    const valueObj = {
      value: [btn.cordX, btn.cordAllY, btn.cordRectX],
      label:
        btn.label === '1'
          ? 'Gore lijevo'
          : btn.label === '2'
            ? 'Gore centar'
            : btn.label === '3'
              ? 'Gore desno'
              : btn.label === '4'
                ? 'Centar lijevo'
                : btn.label === '5'
                  ? 'Centar'
                  : btn.label === '6'
                    ? 'Centart desno'
                    : btn.label === '7'
                      ? 'Dole lijevo'
                      : btn.label === '8'
                        ? 'Dole centar'
                        : 'Dole desno',
    }
    return valueObj
  })

  //changeFileType
  const changeFileType = async (fileId, typeId) => {
    try {
      await changeFileTy({ fileId, typeId })
    } catch (error) {
      toast.error(error.data.message)
    }
  }

  //changeFileCategory
  const changeFileCategory = async (fileId, categoryId) => {
    try {
      await changeFileCat({ fileId, categoryId })
    } catch (error) {
      toast.error(error.data.message)
    }
  }

  //UpdateFile
  const updateFile = async (fileId, fileObj, selectedFile, prevStampKey, companyId) => {
    try {
      const uniqueID = uuidv4()
      let url

      const updatedFileType = {
        advance: fileTypes.advanced,
        KO: fileTypes.koDoc,
        issued: fileTypes.issued,
        importOption: fileTypes.inSlide,
        exportOption: fileTypes.outSlide,
        received: fileTypes.received,
        reExport: fileTypes.reExport,
      }

      const body = {
        ...fileObj,
        ...(taxPartner?.value
          ? {
              clientId: taxPartner?.value,
              clientName: taxPartner?.label,
            }
          : {}),
        ...updatedFileType,
        Valuta: value?.label,
        PDV: PDV?.label,
        address: url || fileObj?.address,
        statusId: fileStatus?.value,
        fileName: selectedFile ? `${selectedFile?.name?.split('.')[0]}-${uniqueID}.pdf` : fileObj?.fileName,
      }

      const formData = new FormData()

      for (let key in body) {
        if (key === 'stampArr') {
          formData.append(key, JSON.stringify(body[key]))
        } else {
          if (key === 'clientId') {
            if (body[key]) formData.append(key, body[key]?.toString())
          } else {
            formData.append(key, body[key]?.toString())
          }
        }
      }

      if (selectedFile) {
        //Upload file to S3
        formData.append('file', sendFile)
      }

      const res = await changeFile({
        fileId,
        prevStampKey,
        companyId,
        body: formData,
      }).unwrap()

      toast.success(res.message)
    } catch (error) {
      toast.error(error.data.message)
    }
  }

  // Get non-stamped file Blob to update the stamp fileds
  const fetchImageData = async (s3Url, name) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_IP}/files/get-file-data`,
        { url: s3Url },
        {
          responseType: 'blob',
        },
      )

      const blob = new Blob([response.data], { type: response.headers['content-type'] })
      const noStampFile = new File([blob], name)

      setSelectedFile(noStampFile)
    } catch (error) {
      console.error('Error fetching image data:', error)
    }
  }

  useEffect(() => {
    const getData = async () => {
      try {
        if (file.address_no_stamp) await fetchImageData(file.address_no_stamp, file.fileName)
      } catch (error) {
        console.log('error', error)
      }
    }

    getData()
  }, [file.address_no_stamp])

  const handleClose = () => {
    onClose()
    setFileCategory()
    setFileStatus()
    setFileType()
    setSelectedFile()
    setFileStamp([])
    setFile({})
  }

  return (
    <>
      <NewModal
        closeModal={handleClose}
        showModal={open}
        page="report"
        setShowModal={onClose}
        onClickClose={onClose}
        titleClose={t('close')}
        titleSubmit={t('yes')}
        title={t('edit')}
        btns
        navigation
        onClickSubmit={saveChanges}
        handleChangePage={handleChangePage}
        handleChangeBack={handleChangeBack}
        disableSubmit={selectedFile && !page}
      >
        <div className="flex flex-col h-[80vh]">
          {/* Form slider */}
          <div className="mb-4">
            <FileFormSlider pageState={pageState} setPageState={setPageState} totalPages={2} />
          </div>
          {/* Pages */}
          <div className="h-full w-full flex flex-col justify-center items-start">
            {file.booked === 1 && !canEditBooked && <p className="text-secondary font-bold text-lg">{t('booked')}</p>}

            {/* First page of edit modal */}
            {pageState === 0 && (
              <div className="w-full flex justify-evenly h-full">
                <div
                  {...getRootProps()}
                  className="w-full cursor-pointer z-20 h-full relative flex flex-col border bg-neutral rounded-xl p-1"
                >
                  <input
                    {...getInputProps()}
                    className="hidden absolute"
                    id="file-input"
                    required
                    accept="application/pdf"
                  />
                  <div className="h-full">
                    <iframe
                      src={urlPdf}
                      frameBorder="0"
                      width="100%"
                      height="100%"
                      title="test"
                      type="application/pdf"
                    ></iframe>
                  </div>
                  {!urlPdf ? (
                    <div className="absolute inset-0 flex justify-center items-center">
                      <PDFDocumentIcon />
                    </div>
                  ) : (
                    <div className="absolute inset-0 flex justify-center items-center"></div>
                  )}
                </div>

                {/* Line */}
                <div className="h-full w-[1px] bg-neutral mx-5"></div>

                <div className="flex flex-col w-full h-full gap-4">
                  <div className="flex gap-4 border-b border-neutral">
                    {/* Recieved */}
                    <DocumentTypeRadioButton
                      checked={fileTypes?.received}
                      onChange={() => handleCheckbox('received')}
                      title={t('recieved')}
                    />

                    {/* Issued */}
                    <DocumentTypeRadioButton
                      checked={fileTypes?.issued}
                      title={t('clasified')}
                      onChange={() => handleCheckbox('issued')}
                    />

                    {/* Import  */}
                    <DocumentTypeRadioButton
                      checked={fileTypes?.inSlide}
                      onChange={() => handleCheckbox('inSlide')}
                      title={t('stamp_import')}
                    />

                    {/* Export */}
                    <DocumentTypeRadioButton
                      title={t('stamp_export')}
                      checked={fileTypes?.outSlide}
                      onChange={() => handleCheckbox('outSlide')}
                    />
                  </div>

                  <div className="h-full w-full overflow-y-auto">
                    <form className="w-full grid grid-cols-3 gap-2 overflow-y-auto items-center justify-center">
                      {Object.entries(editFields || {})?.map((keyValue, index) => {
                        const inputValue = file[keyValue[0]]

                        if (keyValue[0] === 'Stamp ID') {
                          return (
                            <>
                              <TextInputRegular
                                label={keyValue[0]}
                                readOnly
                                defaultValue={keyValue[1]}
                                placeholder={keyValue[0]}
                              />
                            </>
                          )
                        }
                        if (keyValue[0] === 'Klijent' && company?.tax === true) {
                          return (
                            <SelectInputRegular
                              options={taxOptions}
                              value={taxPartner}
                              onChange={(option) => {
                                setTaxPartner(option)
                                setFile((prev) => ({ ...prev, Klijent: option.label }))
                              }}
                              label={keyValue[0]}
                              placeholder={keyValue[0]}
                            />
                          )
                        }
                        if (keyValue[0] === 'Valuta') {
                          return (
                            <SelectInputRegular
                              options={valuesOptions}
                              styles={clientStyles}
                              placeholder={t('value')}
                              value={value || null}
                              onChange={(e) => {
                                setValue(e)
                                setFile((prev) => ({ ...prev, Valuta: e.label }))
                              }}
                              label={keyValue[0]}
                            />
                          )
                        }
                        if (keyValue[0] === 'PDV') {
                          return (
                            <SelectInputRegular
                              options={optionsPDV}
                              styles={clientStyles}
                              placeholder={t('value')}
                              value={PDV || null}
                              onChange={(e) => {
                                setPDV(e)
                                setFile((prev) => ({ ...prev, PDV: e.label }))
                              }}
                              label={keyValue[0]}
                            />
                          )
                        }

                        if (
                          keyValue[0] === 'Datum protokola' ||
                          keyValue[0] === 'Datum dokumenta' ||
                          keyValue[0] === 'Datum predmeta'
                        ) {
                          // The pair key of stamp field
                          // A stamp key kad have multiple names
                          // For example "Location" can be "Mijesto" or "Lokacija"
                          const pairKey =
                            keyValue[0] === 'Datum dokumenta' || keyValue[0] === 'Datum predmeta'
                              ? 'Datum predmeta'
                              : 'Datum prijema'

                          const dateValue = file[pairKey]
                          return (
                            <DateInputRegular
                              placeholderText={dateValue}
                              onChangeRaw={(e) => handleChangeDate(e, 'raw', pairKey, keyValue[0])}
                              onChange={(date) => handleChangeDate(date, 'regular', pairKey, keyValue[0])}
                              name="attributeValue"
                              autoComplete="off"
                              preventOpenOnFocus
                              label={keyValue[0]}
                              initialValue={dateValue}
                            />
                          )
                        }

                        if (keyValue[0] === 'Vrijednost fakture' || keyValue[0] === 'Vrijednost bez PDV') {
                          const value = file[keyValue[0]]

                          return (
                            <NumberInputRegular
                              label={keyValue[0]}
                              placeholder={keyValue[0]}
                              handleChange={handeFileChange}
                              inputKey={keyValue[0]}
                              initialValue={value}
                            />
                          )
                        }

                        if (keyValue[0] === 'Tip dokumenta') {
                          return null
                        }

                        return (
                          <TextInputRegular
                            label={keyValue[0]}
                            defaultValue={inputValue}
                            placeholder={keyValue[0]}
                            onChange={(e) => handeFileChange(e, keyValue[0])}
                          />
                        )
                      })}
                    </form>
                  </div>
                </div>
              </div>
            )}

            {/* Second page of edit modal */}
            {pageState === 1 && (
              <div className="flex gap-4 w-full h-full">
                {/* PDF preview */}
                <div className="w-full cursor-pointer z-20 h-full overflow-y-auto relative flex flex-col border bg-neutral rounded-xl p-1">
                  <input className="hidden absolute" id="file-input" required accept="application/pdf" />
                  <div className="h-full z-10">
                    <iframe
                      src={urlPdf}
                      frameBorder="0"
                      width="100%"
                      height="100%"
                      title="test"
                      type="application/pdf"
                    ></iframe>
                  </div>
                  <div className="absolute inset-0 flex justify-center items-center">
                    <PDFDocumentIcon />
                  </div>
                </div>
                {/* Line */}
                <div className="h-full w-[1px] bg-neutral mx-5"></div>
                <div className="flex flex-col gap-4 w-full">
                  <SelectInputRegular
                    options={selectListPages}
                    value={page}
                    placeholder={t('stamp_on_page')}
                    onChange={(value) => handleSetPage(value)}
                  />
                  <SelectInputRegular
                    options={stampPositionOptons}
                    value={stampPositionState}
                    placeholder={t('stamp_position')}
                    onChange={(e) => changeStampPosition(e, e.value[0], e.value[1], e.value[2])}
                  />
                  <SelectInputRegular
                    options={[
                      { label: t('rotate_90'), value: 90 },
                      { label: t('rotate_180'), value: 180 },
                      { label: t('rotate_270'), value: 270 },
                      { label: t('rotate_360'), value: 0 },
                    ]}
                    placeholder={t('stamp_rotation')}
                    onChange={(option) => handleRotate(option)}
                  />

                  {/* Line */}
                  <div className="h-[1px] w-full bg-neutral my-5"></div>
                  <SelectInputRegular
                    options={statusOptions}
                    placeholder={t('choose_status')}
                    value={fileStatus}
                    onChange={(e) => setFileStatus(e)}
                  />

                  <SelectInputRegular
                    options={categoryOptions}
                    placeholder={t('choose_category')}
                    value={file.Kategorije ? { label: file.Kategorije, value: file.Kategorije } : null}
                    onChange={(e) => setFileCategory(e)}
                  />
                  <SelectInputRegular
                    options={typeOptions}
                    placeholder={t('choose_type')}
                    value={file.Tip ? { label: file.Tip, value: file.Tip } : null}
                    onChange={(e) => setFileType(e)}
                  />
                  {canEditBooked ? (
                    <SelectInputRegular
                      options={bookedOptions}
                      placeholder={t('set_booked')}
                      value={booked}
                      onChange={(e) => setBooked(e)}
                    />
                  ) : null}
                </div>
              </div>
            )}
          </div>
        </div>
      </NewModal>
    </>
  )
}

export default EditFileCategoriesModal
