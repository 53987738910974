import axios from 'axios'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

const usePartners = () => {
  const [partners, setPartners] = useState([])

  const company = useSelector((state) => state.company.company)

  // taxId is ID of company in ERP applicaton
  const comany_tax_id = useMemo(() => company?.taxId, [company])
  const pin = useMemo(() => company?.pin, [company])
  const EPR_API = useMemo(() => company?.ip, [company])

  useEffect(() => {
    const getPartners = async (taxId, pin, ip) => {
      try {
        const data = await axios.post(`${process.env.REACT_APP_API_IP}/protocol/erp-partners`, {
          taxId: +taxId,
          pin,
          ip,
        })

        const partnersArray = Array.isArray(data.data) ? data.data : []

        setPartners(partnersArray)
      } catch (error) {
        toast.error(error)
      }
    }
    if (comany_tax_id && pin && EPR_API) getPartners(comany_tax_id, pin, EPR_API)
  }, [comany_tax_id, EPR_API, pin])

  return partners
}

export default usePartners
